/* .App {
  text-align: center;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0px);
  }
}

/* dev  */

.css-130l5ck-MuiPaper-root-MuiPopover-paper {
  border-radius: 0px !important;
}

.css-fl8y06-MuiButtonBase-root-MuiTab-root {
  min-width: 0px !important;
}

input:focus {
  outline: none;
}

.css-1r654h1-MuiTreeItem-content .MuiTreeItem-label {
  line-height: 42px !important;
  font-size: 15px !important;
}

.css-1r654h1-MuiTreeItem-content {
  flex-direction: row-reverse !important;
  width: auto;
}

.css-130l5ck-MuiPaper-root-MuiPopover-paper {
  max-height: 100vh !important;
}

#style-2::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  border-radius: 30px;
  background-color: rgb(40, 40, 40);
}

#style-2::-webkit-scrollbar {
  width: 6px;
  border-radius: 30px;
  background-color: rgb(40, 40, 40);
}

#style-2::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #4c4c4c;
}

#style-2::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.css-23irg6-MuiGrid-root {
  margin-left: -16px !important;
}

/******************* slick slider start  **********************/

.slick-slider {
  max-width: calc(100vw - 76px) !important;
}

.slick-list {
  /* margin-bottom: 20px !important; */
  height: "100%" !important;
  /* padding-bottom: 24px !important; */
}

.slick-slide {
  height: auto !important;
  padding-right: 0px !important;
  /* width: 10% !important; */
}

.slick-track {
  margin-left: 48px !important;
  overflow: auto !important;
  /* padding-bottom: 24px !important; */
  /* padding-right: 48px !important; */
}

.slick-arrow-right {
  position: absolute !important;
  right: 0 !important;
  top: 0% !important;
  padding: 16px !important;
  border-radius: 0px !important;
  z-index: 1 !important;
  bottom: 0px !important;
  background: linear-gradient(
    270deg,
    rgb(245, 245, 245, 1) 0%,
    rgb(245, 245, 245, 0.6) 50%,
    rgba(245, 245, 245, 0) 100%
  );
}
.slick-arrow-left {
  position: absolute !important;
  left: -0 !important;
  top: 0% !important;
  padding: 16px !important;
  border-radius: 0px !important;
  z-index: 1 !important;
  bottom: 0px !important;
  background: linear-gradient(
    90deg,
    rgb(245, 245, 245, 1) 0%,
    rgb(245, 245, 245, 0.6) 50%,
    rgba(245, 245, 245, 0) 100%
  );
}
.slick-arrow-right:hover {
  background: linear-gradient(
    270deg,
    rgb(245, 245, 245, 1) 0%,
    rgb(245, 245, 245, 0.6) 50%,
    rgba(245, 245, 245, 0) 100%
  ) !important;
}
.slick-arrow-left:hover {
  background: linear-gradient(
    90deg,
    rgb(245, 245, 245, 1) 0%,
    rgb(245, 245, 245, 0.6) 50%,
    rgba(245, 245, 245, 0) 100%
  ) !important;
}

/* .slick-arrow-right > *,
.slick-arrow-left > * {
  transition: transform 1s ease; 
}

.slick-arrow-right:hover > *,
.slick-arrow-left:hover > * {
  transform: scale(
    1.2
  ); 
} */

@media (max-width: 900px) {
  .slick-slider {
    max-width: calc(100vw) !important;
  }
  .slick-track {
    margin-left: 32px !important;
    overflow: auto !important;
  }
}

@media (max-width: 600px) {
  .slick-slider {
    max-width: calc(100vw) !important;
  }
  .slick-track {
    margin-left: 16px !important;
    overflow: auto !important;
  }
}

.category-menu-icons {
  width: 65%;
  height: 65%;
}
.category-menu-icons:hover {
  filter: brightness(200%) !important;
  color: #fff !important;
  width: 65%;
  height: 65%;
}

/******************* slick slider complete  **********************/

/******************* Gallery image wrapper  **********************/

.ImageWrapper:hover .MuiIconButton-root {
  opacity: 1;
}

.no-scroll {
  overflow: hidden;
  /* Add right padding equal to the width of the scrollbar to prevent layout shift */
  padding-right: 6px;
  /* z-index: 99999; */
}

html {
  background-color: #f4f4f4;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.SnackbarContent-root {
  padding: 4px 20px !important;
  font-weight: 600 !important;
  border-radius: 999px !important;
  background-color: #282828 !important;
  min-width: auto !important;
}

/* .SnackbarContent-root.MuiSnackbarContent-message {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
} */

/* .slick-list { margin: 0 -7px;}  */
/* .slick-slide > div {
  padding: 0 12px 0 0;
} */
